import { createApp, h, reactive } from 'vue';
import router from './router';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FullCalendar from 'primevue/fullcalendar';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import CodeHighlight from './AppCodeHighlight';
import i18n from './config/i18n';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Toast2 from '../src/alert/alert.js'
// import LottieVuePlayer from "@lottiefiles/vue-lottie-player";
// import LottieVuePlayer from "../node_modules/@lottiefiles/vue-lottie-player";
import './App.scss';
import axios from 'axios';
//axios.defaults.baseURL = 'https://hanatour-uat.dvconsulting.org/api/v1/';
//axios.defaults.baseURL = 'https://openchat-api-v2.hanatour.com/api/v1/';
//commented
// axios.defaults.baseURL = 'https://hanatour-api2.dvconsulting.org/api/v1';

// // axios.defaults.baseURL = 'https://openchat-api.hanatour.com/api/v1/';
// axios.defaults.headers = {
//     // source:'nutrabbit',
//     // apiKey:'baL21di1202DAI01Ed0OnNiMDa2P3p0M',
//     "x-api-key":'coN21di1202VII01Ed0OnNiMDa2P3pM0', //// DEVELOPMENT
//     //"x-api-key":'coN21di1202VII01Ed0OnNiMDa2P3p0M', ///UAT + LIVE
//     Authorization: "Bearer " +  localStorage.getItem('token'),
//     //token: localStorage.getItem('token'),
//     'Accept-Language':'ko',
//     // 'Access-Control-Allow-Origin':'*',

// };

// axios.interceptors.response.use((response) => {
//     return response
// }, async function (error) {

//     if (error.response.status === 403 ) {
//         const originalRequest = error.config;
//           Toast2.fire({ title: "Session Expired" });
//         // alert('Session Expired')
//         window.location.href = '/login';
//         localStorage.clear();
//         return axios(originalRequest);

//     }
//     return Promise.reject(error);
// });

// axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
// axios.defaults.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
// axios.defaults.headers['Pragma'] = 'no-cache';
// localStorage.removeItem("client")

//commented


// test


 // development server
const hanatourApi = axios.create({
    //baseURL: 'https://hanatour-api2.dvconsulting.org/api/v1', //development
    baseURL: 'https://hanatour-uat.dvconsulting.org/api/v1/', //UAT
    headers: {
      //"x-api-key": 'coN21di1202VII01Ed0OnNiMDa2P3pM0', //development
      "x-api-key": 'coN21di1202VII01Ed0OnNiMDa2P3p0M',
      Authorization: "Bearer " + localStorage.getItem('token'),
      'Accept-Language': 'ko',
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',
    }
  });

  // development server
  const anotherApi = axios.create({
    //baseURL: 'https://hana-api-devserv.dvconsulting.org/api/v1', //development
    baseURL: 'https://hana-api-uatserv.dvconsulting.org/api/v1', //UAT
    headers: {
      "x-api-key": 'coN21di1202VII01Ed0OnNiMDa2P3p0M',
      Authorization: "Bearer " + localStorage.getItem('token'),
      'Accept-Language': 'ko',
      'Access-Control-Allow-Origin': '*',
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      'Pragma': 'no-cache',  
    }
  });


  hanatourApi.interceptors.response.use((response) => {
    return response;
  }, async function (error) {
    if (error.response.status === 403) {
      const originalRequest = error.config;
      Toast2.fire({ title: "Session Expired" });
      window.location.href = '/login';
      localStorage.clear();
      return hanatourApi(originalRequest);
    }
    return Promise.reject(error);
  });
  
  // Interceptors for anotherApi (optional)
  anotherApi.interceptors.response.use((response) => {
    return response;
  }, async function (error) {
    if (error.response.status === 403) {
      const originalRequest = error.config;
      Toast2.fire({ title: "Session Expired" });
      window.location.href = '/login';
      localStorage.clear();
      return anotherApi(originalRequest);
    }
    return Promise.reject(error);
  });

   

// test
const app = createApp({
    render() {
        return h(AppWrapper);
    },
    mounted() {
        this.$primevue.config.locale.dayNames = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
        this.$primevue.config.locale.monthNames = ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'];
        this.$primevue.config.locale.dayNamesMin = ['일', '월', '화', '수', '목', '금', '토'];
        this.$primevue.config.locale.emptyMessage = '사용 가능한 옵션이 없습니다';
        this.$primevue.config.locale.today = '오늘';
        this.$primevue.config.locale.clear = '맑다';
        // this.$appex.chart.locale.toolbar = ['SVG 다운로드'];
    }
});
const globalImage={
    data() {
        return {
            img_daUrl:'http://backend.da-lab.io/'
        }
    },
}
app.config.globalProperties.$appState = reactive({ colorScheme: 'light', isNewThemeLoaded: false });
app.config.globalProperties.$hanatourApi = hanatourApi;
app.config.globalProperties.$anotherApi = anotherApi;
app.mixin(globalImage);
app.use(PrimeVue, {
    ripple: true,
});
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(i18n);

// app.use(LottieVuePlayer);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.use(VueSweetalert2);

app.component('QuillEditor', QuillEditor);
app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FullCalendar', FullCalendar);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);

app.config.globalProperties.$toast = app.config.globalProperties.$toast || app._context.provides.toast;
// Auto Logout
// setTimeout(() => {
//     localStorage.clear();
//     window.location.href = '/login';
// }, 30 * 60 * 1000);
app.mount('#app');

export { hanatourApi, anotherApi };
